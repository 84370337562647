<template>
  <v-card color="#273142">
    <v-card-title color="#dbd7d779">
      <h3 class="mx-auto">Forgot Password</h3>
    </v-card-title>
    <v-divider class="mt-n1"></v-divider>
    <v-form v-model="valid" @submit.prevent="submitEmail">
      <v-card-text>
        <v-alert
        dismissible
        :type="actionClass"
        v-if="message"
        >
          {{ message }}
        </v-alert>
        <v-text-field
          v-model="email"
          label="Email"
          :rules="emailRules"
          required
          prepend-icon="mdi-email"
          :error-messages="emailError"
        ></v-text-field>

        <p>
          Enter the email address associated with your account, and we’ll email
          you a link to reset your password.
        </p>
      </v-card-text>

      <v-card-actions class="text-center mb-5">
        <v-spacer> </v-spacer>
        <v-btn type="submit" :disabled="!valid" color="primary" class="mb-5 pa-5" :loading="loading" >
          Send Reset Link
        </v-btn>
        <v-spacer> </v-spacer>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import validationMxn from "@/mixins/validation_mixin";

export default {
  name: "Login",
  mixins: [validationMxn],
  data() {
    return {
      show: true,
      valid: false,
      email: "",
      loading: false,
      emailError: '',
      actionClass: '',
      message: '',
    };
  },
  methods: {
    ...mapActions['performPostActions'],
    async submitEmail() {
      this.loading = true;
      const payload = {
        email: this.email,
      };

      const fullPayload = {
        params: payload,
        endpoint: '/users/forgot-password',
      };

      const response = await this.performPostActions(fullPayload);
      this.loading = false;
      this.message = response.data.message;
      this.actionClass = response.data.status ? 'success' : 'error';

    }
  }
};
</script>

<style scoped>
.ant {
  background: #273142;
}
.card-header {
  height: 70px;
  border-bottom: 2px solid #dbd7d779;
}
</style>
